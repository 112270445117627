import React from "react";
import FormTextOnly from "../../forms/onlyText";
import BookImage from "../../../assets/images/downloadBook.svg";
import HalfBookImage from "../../../assets/images/mobileHalfBook.png";
const DownLoadChapter = ({ section, event }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="border-2 border-primary-red rounded-lg w-11/12 mx-auto flex flex-col items-center lg:flex-row justify-around items-start px-6 lg:px-8 pt-8 pb-0 lg:py-8">
          <div className="w-full lg:w-3/5">
            <h1 className="text-4xl leading-40 font-bold pb-6">
              {section?.title}
            </h1>
            <p className="text-xl pr-0 lg:pr-8 pb-8">{section?.description}</p>
            <div className="w-full lg:w-2/4">
              <FormTextOnly name="trial" text="Download" event={event} />
            </div>
          </div>
          <div className="pt-8 lg:pt-0 w-full lg:w-max flex justify-center items-center">
            <img
              loading="lazy"
              className="hidden lg:block"
              src={BookImage}
              alt="book"
            />
            <img
              loading="lazy"
              className="block lg:hidden"
              src={HalfBookImage}
              alt="book"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default DownLoadChapter;
