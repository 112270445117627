import React from "react";
import RedButton from "../redButton";
import { Link } from "gatsby";
const DiscoverAttribute = ({ section }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="border-2 border-primary-red rounded-lg w-11/12 mx-auto flex flex-col lg:flex-row justify-around items-center px-6 lg:px-8 pt-6 pb-12 lg:pt-12 lg:pb-12">
          <div className="w-full lg:w-3/5">
            <h1 className="text-4xl leading-40 font-bold pb-5 lg:pb-3">
              {section?.title || ""}
            </h1>
            <p className="text-xl">{section?.description || ""}</p>
          </div>
          <div className="pt-6 lg:pt-0">
            <Link to={section?.btnLink || ""}>
              {" "}
              <RedButton>{section?.btnText || ""}</RedButton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default DiscoverAttribute;
