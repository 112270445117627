import React from "react";
import FacebookIcon from "../../icons/facebook";
import Insagram from "../../icons/instagram";
import LinkedInIcon from "../../icons/linkedIn";
import TwitterIcon from "../../icons/twitter";
import { generateImageUrl } from "../../../sanity";
import Tiktok from "../../icons/Tiktok";
const AuthorOfAttribute = ({ section }) => {
  return (
    <>
      <div className="blogDetail container mx-auto px-5 lg:px-0">
        <div className="border-2 border-primary-red rounded-lg w-full lg:w-4/5 mx-auto flex flex-col lg:flex-row justify-between items-center px-5 lg:px-8 pt-10 pb-12">
          <div className="w-full lg:w-30 hidden lg:block mr-12">
            <div
              style={{
                backgroundImage: `url(${generateImageUrl(
                  section?.image?.asset?._ref
                ).url()})`,
              }}
              className="h-52 w-full bg-no-repeat bg-cover bg-top"
            />
          </div>
          <div className="w-full">
            <div className="flex">
              <div className="block lg:hidden">
                <div
                  style={{
                    backgroundImage: `url(${generateImageUrl(
                      section?.image?.asset?._ref
                    ).url()})`,
                  }}
                  className="h-48 w-48 bg-no-repeat bg-cover bg-top"
                />
              </div>
              <div>
                <h1 className="blogDetailAuthor text-2xl md:text-4xl lg:text-4xl lg:leading-24 font-semibold lg:leading-40 font-bold pl-4 lg:pl-0">
                  {section?.title}
                </h1>
              </div>
            </div>
            <p className="text-xl py-8 pr-0 lg:pr-8">{section?.description}</p>
            <div className="flex">
              {section?.facebook && (
                <a
                  href={section?.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-primary-red mr-5">
                    <FacebookIcon />
                  </div>
                </a>
              )}
              {section?.instagram && (
                <div className="text-primary-red mr-5">
                  <a
                    href={section?.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Insagram />
                  </a>
                </div>
              )}
              {section?.linkedIn && (
                <div className="text-primary-red mr-5">
                  <a
                    href={section?.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
              )}
              {section?.twitter && (
                <div className="text-primary-red mr-5">
                  <a
                    href={section?.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                </div>
              )}
              {section?.tiktok && (
                <div className="text-primary-red mr-5">
                  <a
                    href={section?.tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tiktok />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthorOfAttribute;
