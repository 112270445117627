import React from "react";
import FacebookIcon from "../../icons/facebook";
import TwitterIcon from "../../icons/twitter";
import LinkedInIcon from "../../icons/linkedIn";
import ThreeCircles from "../decorators/threeCircles";
import { generateImageUrl } from "../../../sanity";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { usDateFormat } from "../../../utils";
const HeroSection = ({ blog }) => {
  let currentUrl = typeof window !== "undefined" && window?.location?.href;
  let bgImage =
    blog?.bgImage?.url || generateImageUrl(blog?.hero_image?.asset?._ref).url();
  return (
    <>
      <div className="container mx-auto flex justify-end items-center">
        <div className="w-full lg:w-3/5 mx-auto px-5 xl:px-2 z-10">
          <h1 className="text-4xl lg:text-2.7 leading-42 font-bold pb-3 pr-0">
            {blog?.title}
          </h1>
          <p className="text-dark-beige text-lg pb-8">
            by {blog?.author} on {usDateFormat(blog?.date, "slash")}
          </p>
          {!blog?.hide_share_button && (
            <div className="flex items-center pb-8">
              <div>
                <p className="text-lg leading-24 pr-5 lg:pr-5">Share</p>
              </div>
              <div className="flex">
                <div className="text-primary-red mr-5">
                  <FacebookShareButton url={currentUrl}>
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
                <div className="text-primary-red mr-5">
                  <LinkedinShareButton url={currentUrl}>
                    <LinkedInIcon />
                  </LinkedinShareButton>
                </div>
                <div className="text-primary-red mr-5">
                  <TwitterShareButton url={currentUrl}>
                    <TwitterIcon />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="absolute lg:w-1/4 z-0 hidden lg:block lg:mr-64 xl:w-1/5"
          style={{ zIndex: "-1" }}
        >
          <ThreeCircles />
        </div>
      </div>
      {blog?.hide_hero ? (
        <></>
      ) : (
        <div
          className="h-56 lg:h-96 w-full"
          loading="lazy"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></div>
      )}
    </>
  );
};
export default HeroSection;
