import React from "react";
import SignupRightButton from "../../forms/signUpWithRightButton";
const NewsLetter = ({ title, event }) => {
  return (
    <>
      <div className="container mx-auto px-5 lg:px-0">
        <div className="bg-extra-dark-gray rounded-lg w-full lg:w-4/5 mx-auto flex flex-col lg:flex-row justify-between items-start px-5 lg:px-8 pt-10 pb-4">
          <div className="w-full lg:w-4/5">
            <h1 className="text-4xl leading-40 font-bold">{title}</h1>
          </div>
          <div className="w-full pl-0 lg:pl-8">
            <SignupRightButton name="newsletter" event={event} />
          </div>
        </div>
      </div>
    </>
  );
};
export default NewsLetter;
