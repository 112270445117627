import React from "react";
const NextUp = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="w-full lg:w-3/5 mx-auto px-5 xl:px-2">
            <span className="font-bold text-lg cursor-pointer">Next up</span>
        </div>
      </div>
    </>
  );
};
export default NextUp;
