import React from "react";
import IncorporateLogo from "../../../assets/images/attributeIncorporated.svg";
import { Link } from "gatsby";
const AttributeIncorporated = ({ section }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="bg-extra-dark-gray  rounded-none lg:rounded-lg w-full lg:w-4/5 mx-auto flex flex-col-reverse  lg:flex-row justify-between items-center px-10 lg:px-8 py-12">
          <div className="w-full lg:w-2/5 flex justify-center items-center pt-7 lg:pt-0">
            <img src={IncorporateLogo} alt="incorporate-logo"  loading="lazy"/>
          </div>
          <div className="w-full lg:w-4/5">
            <h1 className="text-4xl leading-40 font-bold">{section?.title}</h1>
            <p className="text-xl pt-3 pb-6">{section?.description}</p>

            <Link to={section?.linkSlug}>
              <button className="text-white w-full lg:w-max py-3 lg:py-0 lg:text-primary-red hover:text-black hover:no-underline no-underline lg:underline bg-primary-red lg:bg-transparent rounded-full">{section?.linkText}</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default AttributeIncorporated;
